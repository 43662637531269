<template>
  <trac-loading v-if="isLoading"></trac-loading>
  <main v-else>
    <div class="flex">
      <h3>This page is under maintenance</h3>
    </div>
    <div class=" grid grid-cols-2 gap-5">
      <div class=" bg-gray-200 shadow-md rounded-md iline-flex p-3">
        <h1 class="py-2">Total Merchants</h1>
        <div class="bg-white text-center rounded-md py-2 px-3 mt-2">
          {{ merchantsSize }} Merchants
        </div>
      </div>
      <div class=" bg-gray-200 shadow-md rounded-md iline-flex p-3">
        <select v-model="signup" class="w-full py-2">
          <option value="Daily">Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
        </select>
        <div class="bg-white text-center rounded-md py-2 px-3 mt-2">
          {{ formatUsers.length }} Signed up Users
        </div>
      </div>
    </div>
    <div>
      <div
        class="flex item-center mt-10 bg-gray-200 shadow-md rounded-md iline-flex p-3"
      >
        <div class="w-128 text-center">Terminal Service</div>
        <div class="w-full r flex justify-between">
          <div class=" text-center w-full">Daily</div>
          <div class=" text-center w-full">Weekly</div>
          <div class=" text-center w-full">Monthly</div>
        </div>
      </div>
      <div class="flex ">
        <div class="w-128 text-center px-2 py-2 bg-gray-200">
          <p class="  p-1 bg-white  ">Citiserve</p>
          <p class=" p-1 bg-gray-200">Global Accelerex</p>
          <p class=" p-1 bg-white">Providus</p>
          <p class=" p-1 bg-gray-200">Traction Netplus</p>
          <p class=" p-1 bg-white">Paystack</p>
        </div>
        <div class="w-full">
          <div class="w-full flex pt-2 bg-gray-200 justify-between">
            <div class=" text-center p-1 bg-white w-full">
              {{
                citiserveDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                citiserveWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                citiserveMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex  bg-gray-200 justify-between">
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                accelerexDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                accelerexWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                accelerexMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex justify-between bg-gray-200 ">
            <div class=" text-center p-1 bg-white w-full">
              {{
                providusDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                providusWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                providusMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex   bg-white justify-between">
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                netPlusDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                netPlusWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                netPlusMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex justify-between bg-gray-200 pb-2 ">
            <div class=" text-center p-1 bg-white w-full">
              {{
                paystackDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                paystackWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                paystackMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10">
      <div
        class="flex item-center mt-10 bg-gray-200 shadow-md rounded-md iline-flex p-3"
      >
        <div class="w-128 text-center">Payments</div>
        <div class="w-full r flex justify-between">
          <div class=" text-center w-full">Daily</div>
          <div class=" text-center w-full">Weekly</div>
          <div class=" text-center w-full">Monthly</div>
        </div>
      </div>
      <div class="flex ">
        <div class="w-128 text-center px-2 py-2 bg-gray-200">
          <p class="  p-1 bg-white  ">Total Payments</p>
          <p class="  p-1 bg-gray-200  ">Pos Payments</p>
          <p class=" p-1 bg-white">Fixed</p>
          <p class=" p-1 bg-gray-200">Cash</p>
          <p class=" p-1 bg-white">Dynamic</p>
          <p class=" p-1 bg-gray-200">Bank Transfer</p>
          <p class=" p-1 bg-white">Online</p>
        </div>
        <div class="w-full">
          <div class="w-full flex pt-2 bg-gray-200 justify-between">
            <div class=" text-center p-1 bg-white w-full">
              {{
                totalDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                totalWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                totalMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex  bg-gray-200 justify-between">
            <div class=" text-center p-1 bg- w-full">
              {{
                posDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg- w-full">
              {{
                posWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg- w-full">
              {{
                posMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex  bg-gray-200 justify-between">
            <div class=" text-center p-1 bg-white w-full">
              {{
                fixedDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                fixedWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                fixedMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex justify-between bg-gray-200 ">
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                cashDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                cashWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                cashMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex   bg-white justify-between">
            <div class=" text-center p-1 bg-white w-full">
              {{
                dynamicDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                dynamicWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                dynamicMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
          <div class="w-full flex  bg-gray-200 justify-between">
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                bankTransferDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                bankTransferWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-gray-200 w-full">
              {{
                bankTransferMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>

          <div class="w-full flex  bg-gray-200 pb-2 justify-between">
            <div class=" text-center p-1 bg-white w-full">
              {{
                onlineDaily
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                onlineWeekly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
            <div class=" text-center p-1 bg-white w-full">
              {{
                onlineMonthly
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      merchantsSize: null,
      signup: "Daily",
      users: [],
      payment: "Daily",
      payments: [],
      citiserveDaily: "",
      citiserveWeekly: "",
      citiserveMonthly: "",
      providusDaily: "",
      providusWeekly: "",
      providusMonthly: "",
      accelerexDaily: "",
      accelerexWeekly: "",
      accelerexMonthly: "",
      netPlusDaily: "",
      netPlusWeekly: "",
      netPlusMonthly: "",
      paystackDaily: "",
      paystackWeekly: "",
      paystackMonthly: "",
      posDaily: "",
      posWeekly: "",
      posMonthly: "",
      fixedDaily: "",
      fixedWeekly: "",
      fixedMonthly: "",
      cashDaily: "",
      cashWeekly: "",
      cashMonthly: "",
      dynamicDaily: "",
      dynamicWeekly: "",
      dynamicMonthly: "",
      bankTransferDaily: "",
      bankTransferWeekly: "",
      bankTransferMonthly: "",
      onlineDaily: "",
      onlineWeekly: "",
      onlineMonthly: "",
      totalDaily: "",
      totalWeekly: "",
      totalMonthly: "",
    };
  },
  computed: {
    formatUsers() {
      let payload = {};
      payload.startDate = this.moment().format("YYYY-MM-DD");
      if (this.signup === "Daily") {
        return [...this.users].filter((x) => {
          return (
            moment(x.created_at).format("YYYY-MM-DD") ==
            moment().format("YYYY-MM-DD")
          );
        });
      } else if (this.signup === "Weekly") {
        let today = moment().format("YYYY-MM-DD");
        let week = moment()
          .subtract(7, "d")
          .format("YYYY-MM-DD");
        return [...this.users].filter((x) => {
          let date = this.moment(x.created_at).format("YYYY-MM-DD");
          return date >= week && date <= today;
        });
      } else {
        let month = this.moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        let today = this.moment().format("YYYY-MM-DD");
        return [...this.users].filter((x) => {
          let date = this.moment(x.created_at).format("YYYY-MM-DD");
          return date >= month && date <= today;
        });
      }
    },
  },
  methods: {
    async paymentsDaily() {
      let res = {};
      res.startTime = this.moment("8:00 PM", "HH:mm").format("HH:mm");
      res.endTime = this.moment().format("HH:mm");
      res.startdatetime = this.moment().format("YYYY-MM-DD");
      res.enddatetime = this.moment().format("YYYY-MM-DD");
      let resp = await this.$store.dispatch("FETCH_PAYMENT_LOGS", res);
      let s = resp.data.items;
      let sum = [...s].reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.totalDaily = sum;

      let citiserve = [...s].filter((x) => x.payment_channel === "citiserve");
      let citiserveSum = citiserve.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.citiserveDaily = citiserveSum;

      let accelerex = [...s].filter((x) => x.payment_channel === "accelerex");
      let accelerexSum = accelerex.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.accelerexDaily = accelerexSum;

      let providus = [...s].filter((x) => x.payment_channel === "providus");
      let providusSum = providus.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.providusDaily = providusSum;

      let netplus = [...s].filter(
        (x) => x.payment_channel === "traction_netplus"
      );
      let netplusSum = netplus.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.netPlusDaily = netplusSum;

      let paystack = [...s].filter((x) => x.payment_channel === "paystack");
      let paystackSum = paystack.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.paystackDaily = paystackSum;

      let pos = [...s].filter((x) => x.payment_method === "pos");
      let posSum = pos.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.posDaily = posSum;

      let fixed = [...s].filter((x) => x.payment_method === "fixed_account");
      let fixedSum = fixed.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.fixedDaily = fixedSum;

      let cash = [...s].filter((x) => x.payment_method === "cash");
      let cashSum = cash.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.cashDaily = cashSum;

      let dynamic = [...s].filter((x) => x.payment_method === "bank_transfer");
      let dynamicSum = dynamic.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.dynamicDaily = dynamicSum;

      let bankTransfer = [...s].filter(
        (x) => x.payment_method === "own_bank_account"
      );
      let bankTransferSum = bankTransfer.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.bankTransferDaily = bankTransferSum;

      let online = [...s].filter((x) => x.payment_method === "online_payment");
      let onlineSum = online.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.onlineDaily = onlineSum;
    },
    async paymentsWeekly() {
      let res = {};
      res.startTime = this.moment("8:00 PM", "HH:mm").format("HH:mm");
      res.endTime = this.moment().format("HH:mm");
      res.startdatetime = this.moment()
        .subtract(7, "d")
        .format("YYYY-MM-DD");
      res.enddatetime = this.moment().format("YYYY-MM-DD");
      let resp = await this.$store.dispatch("FETCH_PAYMENT_LOGS", res);
      let s = resp.data.items;
      let sum = [...s].reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.totalWeekly = sum;
      let citiserve = [...s].filter((x) => x.payment_channel === "citiserve");
      let citiserveSum = citiserve.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.citiserveWeekly = citiserveSum;

      let accelerex = [...s].filter((x) => x.payment_channel === "accelerex");
      let accelerexSum = accelerex.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.accelerexWeekly = accelerexSum;

      let providus = [...s].filter((x) => x.payment_channel === "providus");
      let providusSum = providus.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );

      let netplus = [...s].filter(
        (x) => x.payment_channel === "traction_netplus"
      );
      let netplusSum = netplus.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.netPlusWeekly = netplusSum;

      let paystack = [...s].filter((x) => x.payment_channel === "paystack");
      let paystackSum = paystack.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.paystackWeekly = paystackSum;

      this.providusWeekly = providusSum;
      let pos = [...s].filter((x) => x.payment_method === "pos");
      let posSum = pos.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.posWeekly = posSum;

      let fixed = [...s].filter((x) => x.payment_method === "fixed_account");
      let fixedSum = fixed.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.fixedWeekly = fixedSum;

      let cash = [...s].filter((x) => x.payment_method === "cash");
      let cashSum = cash.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.cashWeekly = cashSum;

      let dynamic = [...s].filter((x) => x.payment_method === "bank_transfer");
      let dynamicSum = dynamic.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.dynamicWeekly = dynamicSum;

      let bankTransfer = [...s].filter(
        (x) => x.payment_method === "own_bank_account"
      );
      let bankTransferSum = bankTransfer.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.bankTransferWeekly = bankTransferSum;

      let online = [...s].filter((x) => x.payment_method === "online_payment");
      let onlineSum = online.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.onlineWeekly = onlineSum;
    },

    async paymentsMonthly() {
      let res = {};
      res.startTime = this.moment("8:00 PM", "HH:mm").format("HH:mm");
      res.endTime = this.moment().format("HH:mm");
      res.startdatetime = this.moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      res.enddatetime = this.moment().format("YYYY-MM-DD");
      let resp = await this.$store.dispatch("FETCH_PAYMENT_LOGS", res);
      let s = resp.data.items;
      let sum = [...s].reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.totalMonthly = sum;
      let citiserve = [...s].filter((x) => x.payment_channel === "citiserve");
      let citiserveSum = citiserve.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.citiserveMonthly = citiserveSum;

      let accelerex = [...s].filter((x) => x.payment_channel === "accelerex");
      let accelerexSum = accelerex.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.accelerexMonthly = accelerexSum;

      let providus = [...s].filter((x) => x.payment_channel === "providus");
      let providusSum = providus.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.providusMonthly = providusSum;

      let netplus = [...s].filter(
        (x) => x.payment_channel === "traction_netplus"
      );
      let netplusSum = netplus.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.netPlusMonthly = netplusSum;

      let paystack = [...s].filter((x) => x.payment_channel === "paystack");
      let paystackSum = paystack.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.paystackMonthly = paystackSum;

      let pos = [...s].filter((x) => x.payment_method === "pos");
      let posSum = pos.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.posMonthly = posSum;

      let fixed = [...s].filter((x) => x.payment_method === "fixed_account");
      let fixedSum = fixed.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.fixedMonthly = fixedSum;

      let cash = [...s].filter((x) => x.payment_method === "cash");
      let cashSum = cash.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.cashMonthly = cashSum;

      let dynamic = [...s].filter((x) => x.payment_method === "bank_transfer");
      let dynamicSum = dynamic.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.dynamicMonthly = dynamicSum;

      let bankTransfer = [...s].filter(
        (x) => x.payment_method === "own_bank_account"
      );
      let bankTransferSum = bankTransfer.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.bankTransferMonthly = bankTransferSum;

      let online = [...s].filter((x) => x.payment_method === "online_payment");
      let onlineSum = online.reduce(
        (a, b) => a + b.recipient_details.payment_details.amount_paid,
        0
      );
      this.onlineMonthly = onlineSum;
    },
  },

  async created() {
    this.isLoading = true;
    let merchantSize = await this.$store.dispatch("GET_ALL_BUSINESSES");
    this.merchantsSize = merchantSize.data.items.length;
    let userSignupSize = await this.$store.dispatch("FETCH_USERS");
    this.users = userSignupSize.data;
    await this.paymentsDaily();
    await this.paymentsWeekly();
    await this.paymentsMonthly();
    this.isLoading = false;
  },
};
</script>

<style></style>
